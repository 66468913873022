/**
 *
 * Converts Address Object Into String Address
 * @example addressObjectToString({...addressObject})
 * @param {object} key - const addressObject = { street: string, city: string, state: string, postalCode: string, country: string }
 * @returns {string} returns a Sentence Case string = New text body
 *
 */

const addressObjectToString = addressObject => {
  const { street, city, state, postalCode, country } = addressObject
  return `${street}, ${city}, ${state}, ${postalCode}, ${country}`
}

export default addressObjectToString
