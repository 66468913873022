import './src/gatsby-plugin-theme-ui/fonts.css'
import React from 'react'
import Layout from './src/components/Layout'
import { ContextProvider } from './src/context/store'

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ContextProvider>
      <Layout {...props}>{element}</Layout>
    </ContextProvider>
  )
}
