import React from 'react'
import { Box } from 'theme-ui'
import Title from '../Text/Title'
import Subtitle from '../Text/Subtitle'
import BodyText from './BodyText'
import ButtonsRenderer from '../Buttons/ButtonsRenderer'
export default function TextContent({ title, subtitle, text, buttons, children, ...rest }) {
  return (
    <Box {...rest} sx={{ ...styles.container, ...rest.sx }}>
      {title ? <Title>{title}</Title> : ''}
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : ''}
      {text ? <BodyText body={text} /> : ''}
      {buttons?.length > 0 ? <ButtonsRenderer buttons={buttons} /> : ''}
      {children}
    </Box>
  )
}

const styles = {
  container: {}
}
