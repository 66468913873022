import React from 'react'
import { Heading } from 'theme-ui'

export default function Title(props) {
  return (
    <Heading as="h2" className="title" {...props} sx={{ ...styles.container, ...props.sx }}>
      {props.children}
    </Heading>
  )
}

const styles = {
  container: {
    color: 'tertiary',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '1.5rem',
    fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem']
  }
}
