import React from 'react'
import { Box } from 'theme-ui'

import ContentBlockCenter from '../components/ContentBlocks/ContentBlockCenter'

import PageHero from '../components/Heros/PageHero'
import ContentBlockMain from '../components/ContentBlocks/ContentBlockMain'
import FranchiseForm from '../components/Forms/FranchiseForm'

export default function FranchiseComponent() {
  return (
    <Box sx={styles.container}>
      <PageHero imageID={'franchises-pagehero'} title={'Franchises'} />
      <Box sx={styles.franchise1}>
        <ContentBlockCenter storyId={'a-art-CSRyqgjMRf65O88s2-x7-g'} />
      </Box>

      <Box sx={styles.franchise2}>
        <ContentBlockMain storyId={'a-art-UCbDcHytTamD7sbZGl4xKg'} />
      </Box>
      <Box sx={styles.franchise3}>
        <ContentBlockCenter storyId={'a-art-Udx7fcsyRPapiKW-6fkztw'} />
      </Box>
      <Box sx={styles.formContainer}>
        <FranchiseForm formName="franchise" />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column'
  },
  franchise1: {
    '.title': {
      color: 'primary'
    }
  },

  franchise2: {
    'div.imageContainer1': {
      order: ['1', '', '1'],
      width: ['', '', '40%']
    },
    'div.imageContainer2': {
      order: ['1', '', '3'],
      display: ['none', '', 'inline-block']
    },
    '.content': {
      order: ['1', '', '2'],
      backgroundColor: 'tertiary',
      color: 'white',
      width: ['', '', '60%'],
      position: 'relative',
      top: ['', '', '4rem'],
      left: ['', '', '-4rem'],
      '.title': {
        fontFamily: 'heading',
        color: 'secondary',
        fontWeight: 'bold'
      },
      '.subtitle': {
        fontFamily: 'heading',
        color: 'white',
        fontWeight: 'bold'
      }
    },

    marginBottom: '20vh'
  },
  franchise3: {},

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem'
    // backgroundColor: 'secondary',
  }
}
