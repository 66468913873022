import React from 'react'
import { Box, Text, Heading, Image } from 'theme-ui'
import Address from '../UI/Address'
import ContactFormPopUp from '../UI/ContactFormPopUp'
import HoursPopUp from '../Hours/HoursPopUp'

export default function BottomNav({ businessData }) {
  return (
    <Box sx={styles.container} id="navigationBottom">
      <Box sx={styles.logo}>© Banana King {new Date().getFullYear()}</Box>
      <HoursPopUp businessData={businessData} />
      {/* <Address sx={styles.address} /> */}
      <Box
        sx={{
          width: '2px',
          backgroundColor: 'white',
          height: '1rem',
          margin: 'auto 1rem'
        }}
      >
        {'  '}
      </Box>
      <ContactFormPopUp />
    </Box>
  )
}

const styles = {
  container: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'primary',
    height: '42px',
    padding: ['0.5rem', '', '0.5rem 2rem'],
    backgroundColor: 'tertiary',
    zIndex: '6000',
    display: 'flex',
    color: 'white'
  },
  logo: {
    display: ['none', '', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    maxHeight: '100%',
    img: {
      height: '100%',
      marginRight: '2'
    }
  },

  address: {
    // fontSize: '0.7rem',
    opacity: '0.8',
    // margin: '0rem 1rem 0rem auto',
    padding: '0rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1'
  }
}
