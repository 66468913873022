import React, { useContext } from 'react'
import { Image } from 'theme-ui'
import { store } from '../../context/store'

export default function Logo({ logoOveride, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const { imageBaseUrl, imagePrefix } = globalState?.businessData?.avatar

  const logo = imageBaseUrl + '/' + imagePrefix

  return <Image alt="Moderne Barn logo" src={logoOveride ? logoOveride : logo} {...rest} />
}
