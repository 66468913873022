import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Image, Text, Link as ExternalLink } from 'theme-ui'
// import Logo from '../../assets/logo.png'
import { footerLinks } from '../navLinks'
import Logo from '../UI/Logo'
import TopFooter from './TopFooter'
import LinkSelector from '../ComponentSelectors/LinkSelector'
import GonationLogoPowered from '../GeneralComponents/GonationLogoPowered'

export default function Footer({ businessData }) {
  const renderFooterLinks = () =>
    footerLinks.map(footerLink => {
      const { name, link, icon } = footerLink
      return (
        <LinkSelector to={link} key={`footerLink-${name}`}>
          {name}
        </LinkSelector>
      )
    })

  return (
    <>
      <TopFooter businessData={businessData} />

      <Box sx={styles.container} id="footer">
        <Logo sx={styles.logo} businessData={businessData} />

        <Box sx={styles.footerLinks}>{renderFooterLinks()}</Box>
      </Box>
      <Box sx={styles.gonationBar}>
        <GonationLogoPowered />
      </Box>
    </>
  )
}
const styles = {
  // ? === containers ===
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#353535',
    position: 'relative'
  },
  logo: {
    // filter: 'brightness(0)',
    maxHeight: '100px',
    paddingRight: ['', '', '2rem']
  },
  footerLinks: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: ['center', '', 'flex-start'],
    alignItems: 'center',
    borderLeft: ['', '', 'solid 1px lightgrey'],
    a: {
      color: 'white',
      margin: '1rem',
      transition: 'all ease-in-out 0.3s',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
        color: 'grey'
      }
    }
  },

  gonationBar: {
    width: '100%',
    padding: '0.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'black',
    alignItems: 'center',
    paddingBottom: '3.25rem'
  }
}
