import React from 'react'
import { Box, Heading } from 'theme-ui'
import ContactForm from '../components/ContactForm'
import PageHero from '../components/Heros/PageHero'
import TextContent from '../components/Text/TextContent'

export default function ContactComponent() {
  return (
    <Box sx={styles.container}>
      <PageHero imageID={'contact-pagehero'} title={'Contact Us'} />
      <Box sx={styles.row}>
        <Box sx={styles.textContent}>
          <TextContent
            title={'Get In Touch'}
            subtitle={'QUESTIONS? COMMENTS?'}
            text={
              'If you have any questions or comments, send us a message and we will get back to you as soon as possible.'
            }
          />
        </Box>
        <Box sx={styles.hours} style={styles.hours}>
          <Heading
            as="h2"
            sx={{
              color: 'primary',
              fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem'],
              marginBottom: '1rem',
              textTransform: 'uppercase'
            }}
          >
            Hours
          </Heading>
          <Heading sx={{ color: 'tertiary' }}>Open 24 hours every day!</Heading>
        </Box>
      </Box>
      <Box sx={styles.textContentForm}>
        <TextContent title={'Send Us A Message'} subtitle={'Here To Help'} />
      </Box>
      <Box sx={styles.contactFormContainer}>
        <ContactForm formName="Contact" sx={styles.contactForm} style={styles.contactForm} />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column'
    // padding: '1rem',
  },

  row: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: ['0rem', '1rem']
  },

  textContent: {
    padding: '0rem 1rem',
    width: ['100%', '', '50%'],
    flexGrow: '1',

    '.body': {
      maxWidth: '500px'
    }
  },

  hours: {
    width: ['100%', '', '50%'],
    flexGrow: '1',
    padding: '0rem 1rem',
    borderLeft: ['', '', 'solid 1px lightgrey'],
    '.innerHoursContainer': {
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      flexWrap: 'wrap'
      // 'div:last-child': {
      //   paddingRight: '0rem',
      // },
    },
    '.heading': {
      width: '100%',
      marginBottom: '1.5rem',
      color: 'tertiary',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      letterSpacing: '2px',
      marginBottom: '1rem',
      fontSize: ['1.75rem', '2rem']
      // fontFamily: 'Playfair Display, serif',
    },
    '.typeContainer': {
      padding: '1rem 1rem 0rem 0rem',
      order: '2'
    },
    '.typelabel': {
      color: 'primary',
      textTransform: 'uppercase',
      marginBottom: '0.5rem',
      fontSize: '1.2rem'
    },
    '.day': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '1rem'
    },
    '.time': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '0.9rem',
      color: 'grey'
    },

    '.brunch': {
      order: '1'
    }
  },
  textContentForm: {
    padding: ['3rem 1rem', '', '3rem 2rem'],
    marginTop: ['2rem', '', '0rem'],

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'secondary'
  },
  contactFormContainer: {
    backgroundColor: 'secondary'
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: ['0rem 1rem 4rem', '', '0rem 2rem 4rem'],
    maxWidth: '600px',
    margin: '0 auto',
    input: {
      borderColor: 'tertiary',
      borderRadius: '0px',
      color: 'black'
    },
    textarea: {
      borderColor: 'tertiary',
      borderRadius: '0px',
      color: 'black'
    },
    '.submitBtn': {
      marginTop: '2rem',
      padding: '1rem 3rem'
    }
  },
  phone: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: 'grey',
    marginBottom: '1rem',
    fontSize: '1rem',
    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  },
  address: {
    color: 'grey',
    marginBottom: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    fontSize: '1rem',

    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  }
}
