import React, { useContext } from 'react'
import { Textarea, Input, Button, Box, Heading, Label } from 'theme-ui'

export default function FranchiseForm(props) {
  const { sx, style } = props
  const formName = 'franchise-form'

  return (
    <Box
      sx={{ ...styles.container, ...style, ...sx }}
      as="form"
      name={formName}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <Heading as="h3" sx={styles.heading}>
        Franchise Inquiry Form
      </Heading>
      <Input type="hidden" name="form-name" value={formName} />
      <Label for="name">Name</Label>
      <Input sx={styles.input} name="name" type="text" id="name" placeholder="Full Name" required />
      <Label for="phone">Contact Number</Label>
      <Input sx={styles.input} type="phone" name="phone" id="phone" placeholder="Phone" className="inputField email" />
      <Label for="email">Email</Label>
      <Input
        sx={styles.input}
        type="email"
        name="email"
        id="email"
        placeholder="Your Email Address"
        className="inputField email"
        required
      />

      <Box sx={styles.row}>
        <Box>
          <Label for="city">City</Label>
          <Input sx={styles.input} name="city" type="text" id="city" placeholder="City" required />
        </Box>
        <Box>
          <Label for="state">State</Label>
          <Input sx={styles.input} name="state" type="text" id="state" placeholder="State" required />
        </Box>
        <Box>
          <Label for="zipcode">Zipcode</Label>
          <Input sx={styles.input} name="zipcode" type="text" id="zipcode" placeholder="Zipcode" required />
        </Box>
      </Box>

      {/* <Box sx={styles.checkboxContainer}>
        <label for='flexibledate'>My date is flexible</label>
        <Input
          sx={styles.checkbox}
          type='checkbox'
          id='flexibledate'
          name='flexibledate'
          value='Flexible On Date'
        />
      </Box> */}
      <Label for="message">Message</Label>
      <Textarea
        sx={styles.textArea}
        name="message"
        id="message"
        rows="4"
        placeholder="Tell Us About Any Additional Information"
      />
      <Button className="button submitBtn" type="submit">
        Submit
      </Button>
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    maxWidth: '600px',
    padding: '4rem 2rem',
    border: 'solid 1px white',
    margin: '2rem 0rem',
    label: {
      margin: '0rem',
      backgroundColor: 'tertiary',
      padding: '0.1rem 0.25rem',
      borderRadius: '10px 10px 0px 0px',
      fontSize: '0.8rem',
      width: 'fit-content',
      color: 'white'
    }
  },
  heading: {
    color: 'primary',
    textDecoration: 'underline',
    textAlign: 'center',
    width: '100%',
    textTransform: 'capitalize',
    marginBottom: '1.5rem',
    fontSize: ['1.5rem', '1.75rem', '2rem']
  },

  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '> div': {
      //   flexGrow: '1',s
      width: '31%'
    }
  },

  input: {
    border: 'none',
    border: 'solid 2px',
    borderColor: 'tertiary',
    margin: '0rem 0rem 0.5rem',
    borderRadius: '0px',
    color: 'dark',
    '::placeholder': {
      fontSize: '0.8rem',
      fontFamily: 'body',
      letterSpacing: '0px',
      color: 'white',

      fontWeight: '400'
    }
  },

  date: {
    border: 'none',
    border: 'solid 1px lightgrey',

    '::before': {
      margin: '0rem 0rem',
      content: "'Date of Event'",
      width: '100%',
      fontSize: '0.8rem',
      letterSpacing: '0px',
      fontFamily: 'body',
      color: 'grey',
      fontWeight: '400'
    },

    ':hover::before': {
      width: '0%',
      content: "''"
    }
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1rem 0rem',
    padding: '0rem 0.5rem',
    width: 'fit-content',
    label: {
      margin: '0rem 0rem',
      content: "'Date of Event'",
      width: '100%',
      fontSize: '0.8rem',
      letterSpacing: '0px',
      fontFamily: 'body',
      color: 'grey',
      fontWeight: '400'
    }
  },
  checkbox: {
    width: '15px',
    height: '15px',
    appearance: 'auto',
    // border: 'unset',
    padding: '0.5rem',
    marginLeft: '1rem'
  },
  textArea: {
    border: 'none',
    border: 'solid 2px',
    borderColor: 'tertiary',
    borderRadius: '0px',
    margin: '0.5rem 0rem',
    resize: 'none',
    color: 'dark',

    '::placeholder': {
      fontSize: '0.8rem',
      fontFamily: 'body',
      letterSpacing: '0px',
      color: 'white',

      fontWeight: '400'
    },
    marginBottom: '1.5rem'
  }
}
