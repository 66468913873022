import React, { useContext } from 'react'
import { Flex, Heading, Text } from '@theme-ui/components'
import findPoweredImageURLById from '../../helpers/findPoweredImageURLById'
import { store } from '../../context/store'

export default function PageHero({ title, imageID, sx, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const pageHeroImage = findPoweredImageURLById(imageID, globalState.poweredImages)

  const backgroundImageStyle = {
    backgroundImage: `url(${pageHeroImage})`
  }

  return (
    <Flex {...rest} sx={{ ...styles.container, ...backgroundImageStyle, ...sx }}>
      {title && (
        <Flex>
          <Heading as="h1" sx={styles.title}>
            {title}
          </Heading>
        </Flex>
      )}
    </Flex>
  )
}

const styles = {
  container: {
    margin: '1rem',
    height: '75vh',
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative'
  },
  title: {
    color: 'light',
    backgroundColor: 'primary',
    padding: '2rem',
    textTransform: 'uppercase',
    position: 'absolute',
    left: '0rem',
    bottom: '1rem'
  }
}
