import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios'
import addressObjectToString from '../helpers/addressObjectToString'

export default function useBusinessData() {
  const [businessData, setBusinessData] = useState(false)

  const data = useStaticQuery(graphql`
    query locations {
      allSite {
        edges {
          node {
            id
            siteMetadata {
              locationIds {
                id
                locationName
                links {
                  category
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  // get hardcoded data from config
  const locations = data.allSite.edges[0].node.siteMetadata.locationIds

  const fetchBusinessData = async () => {
    const locationsData = await Promise.all(
      locations.map(({ id }) => {
        return axios.get(`https://www.gonation.com/api/proxy/v2/businesses/${id}`)
      })
    )

    // breaksdown the nested levels of data.
    const parsedLocationData = locationsData.map(location => {
      return location.data.business
    })

    // the hardcoded data needs to be combined with data from gonation. the merge happens below
    const mergedData = locations.map((location, index) => {
      const matchingLocation = parsedLocationData[index]

      const cover = `https://res.cloudinary.com/gonation/${matchingLocation.cover.image.cloudinaryId}`

      const coordinates = [matchingLocation.location.coordinates.lat, matchingLocation.location.coordinates.lng]

      const locationName = matchingLocation.name
      const phone = matchingLocation.publishableData.contact.phone
      const address = addressObjectToString(matchingLocation.location.address)

      return {
        ...location,
        cover,
        coordinates,
        locationName,
        phone,
        address,
        addressObject: matchingLocation.location.address
      }
    })

    setBusinessData(mergedData)
  }

  useEffect(() => {
    if (locations) fetchBusinessData()

    return () => {}
  }, [])

  return businessData
}
