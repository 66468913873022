import React, { useEffect, useRef } from 'react'
import mapboxgl from '!mapbox-gl'
import '../../gatsby-plugin-theme-ui/map.css'
// import { points } from '../../constants/MapPoints';
import { Box } from 'theme-ui'
import googleString from '../../helpers/getGoogleAddress'
const buildPointObject = (locationName, address, addressObject, phone, links, cover, coordinates) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates
    },
    properties: {
      locationName,
      address,
      addressObject,
      phone,
      address,
      cover,
      links,
      iconSize: [40, 40],
      pinImage: 'https://res.cloudinary.com/gonation/image/upload/v1649179744/sites/banana-king/bksun_1.png'
    }
  }
}

export default function InteractiveMap({ locations, centerCoordinates = [], zoom = '16' }) {
  const mapContainer = useRef()

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZGV2Z29uYXRpb24iLCJhIjoiY2wxNTN5YW1uMDkwZzNkdnk1M2k4ejJ3YSJ9.FshTwVE6gMUrmx-uetF2Ow'

    const points = locations.map(location => {
      const { locationName, address, addressObject, phone, coordinates, cover, links } = location

      const switchCoordinates = [coordinates[1], coordinates[0]]

      return buildPointObject(locationName, address, addressObject, phone, links, cover, switchCoordinates)
    })

    const geojson = {
      type: 'FeatureCollection',
      features: points
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: centerCoordinates,
      zoom
    })

    // add markers to map
    geojson.features.forEach(function (marker) {
      // Create a DOM element for each marker.
      const el = document.createElement('div')
      el.className = 'marker'
      el.style.backgroundImage = `url(${marker.properties.pinImage})`
      el.style.width = marker.properties.iconSize[0] + 'px'
      el.style.height = marker.properties.iconSize[1] + 'px'
      el.style.backgroundSize = '100%'

      const { locationName, address, addressObject, phone, cover, links } = marker.properties

      // if (locationName === 'Banana King Seacaucus') {
      //   locationName = locationName + '- Coming Soon';
      // }

      const { city, state, street, zip } = addressObject
      const googlehref =
        'https://www.google.com/maps/dir/?api=1&destination=' + street + '+' + city + '+' + state + '+' + zip

      // Add markers to the map.
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups

            .setHTML(
              `
                      <div class="locationPopUp">
                        <div class="imageContainer">
                          <img src="${cover}" alt="location image"/>
                        </div>
                        <div class="content">
                        <h3>${locationName}</h3>
                          <a class="textlink" href="${googlehref}" target="_blank" >${address}</p>
                          <a class="textlink" href="tel:${phone}"}>${phone}</a>
                          <div class='links'>
                          ${links
                            .map(link => {
                              return `<a href="${link.url}" target="__blank">${link.name}</a>`
                            })
                            .join('')}
                          </div>
                        <div>
                      </div>
                    `
            )
        )
        .addTo(map)
    })

    return () => {}
  }, [])

  return <Box className="mbgl-container" sx={styles.container} ref={mapContainer}></Box>
}

const styles = {
  container: {
    '.locationPopUp': {
      display: 'flex',
      flexDirection: 'column',

      '.imageContainer': {
        width: '100%',
        height: '150px',
        flex: 'none',
        img: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }
      },
      '.content': {
        padding: '0.5rem 0rem',
        h3: {
          fontSize: '16px',
          color: 'primary',
          textTransform: 'uppercase',
          letterSpacing: '0px'
        },

        h4: {
          color: 'secondary'
        },

        '.textlink': {
          color: 'grey',
          fontWeight: 'bold'
        },
        '.links': {
          marginTop: '1.5rem',
          a: {
            backgroundColor: 'tertiary',
            padding: '0.5rem 0.75rem',
            fontFamily: 'heading',
            fontWeight: 'bold',
            color: 'secondary',
            textTransform: 'uppercase',
            fontSize: '13px',
            display: 'inline-block',
            margin: '0rem 0.25rem 0.25rem'
          }
        }
      }
    }
  }
}

InteractiveMap.defaultProps = {
  locations: [],
  centerCoordinates: [-74.1454, 40.7684],
  zoom: '10'
}
