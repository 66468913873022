/**
 *
 * *This takes all the stories and filters the one it wants by id
 * Then returns and object of data for that story else return empy object
 *
 * @param {string} storyId - the id of the story from GN
 * @param {array} allGeneral - All Stories in an Array.
 * @param {boolean} isArrayWithinArray - (UnderWorks) nested block of arrays in arrays
 */

export default (storyId, allGeneral, isArrayWithinArray) => {
  if (storyId && allGeneral) {
    const story = allGeneral.filter(({ node }) => node.id === storyId)?.[0]?.node // !should only ever return one element in a array
    if (story) {
      // returns a object of the story data

      const createImagesArray = () => {
        return story.media.map(image => {
          return `https://res.cloudinary.com/gonation/${image.cloudinaryId}`
        })
      }
      return {
        title: story.title,
        subtitle: story.subtitle,
        body: story.bodyHTML?.replace(/\s/g, '') !== '<p></p>' ? story.bodyHTML : '', // regex replace needed to remove spaces and tabs
        images: createImagesArray(),
        buttons:
          story.ctaLink?.title || story.ctaLink?.url
            ? [
                {
                  name: story.ctaLink?.title,
                  link: story.ctaLink?.url
                }
              ]
            : ''
      }
    } else {
      console.log('Story Could Not Be Found By Id')
      return {}
    }
  } else {
    return {}
  }
}
