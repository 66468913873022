const variantName = 'menuV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['0.5rem', '1rem'],
      justifyContent: 'center',
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      backgroundColor: 'primary',
      border: 'none',
      margin: '0.5rem',
      cursor: 'pointer',
      justifyContent: 'center',
      paddingBottom: 'calc(0.5rem - 1px)',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: 'calc(0.5rem - 1px)',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '100%' // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      position: ['fixed', '', '', 'fixed'],
      top: '62px',
      right: '0rem',
      width: ['100%', '', '', '50%'],
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      '-webkit-overflow-scrolling': 'touch',
      padding: ['0.5rem 0rem', '1rem 0rem', '1rem 1rem'],
      borderBottom: 'solid 1px lightgrey',
      backgroundColor: 'white',
      zIndex: '100',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px'
      }
    },

    sectionTab: {
      flexGrow: '1',
      display: 'flex',
      // display: 'inline-block',
      textAlign: 'center',
      justifyContent: 'center',
      margin: '0.25rem',
      width: 'fit-content',
      border: '1px solid black',
      padding: '0.25rem 0.5rem',
      cursor: 'pointer',
      color: 'text',
      fontSize: '0.9rem',

      '&:hover': {
        backgroundColor: 'secondary',
        color: '#fff',
        transition: 'all 0.5s'
      }
    },

    sectionTabActive: {
      variant: `${variantName}.sectionTab`,
      backgroundColor: 'primary',
      color: 'light'
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainer: {},
    menuContainer: { paddingBottom: '2rem' },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    sectionCell: {
      cursor: 'pointer',
      width: [
        '100%',
        'calc(50% - (0.5rem * 2))',
        'calc(33.333% - (0.5rem * 2))',
        'calc(33% - (0.5rem * 2))',
        'calc(25% - (0.5rem * 2))'
      ],
      backgroundColor: 'secondary',
      borderRadius: '10px',
      margin: ['1rem 0', '0.5rem']
    },

    menuCell: {
      flexWrap: 'wrap',
      border: 'solid 1px black',
      borderRadius: '10px',
      height: '100%',
      backgroundColor: 'background'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px 10px 0 0'
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px 10px 0 0'
    },
    cellName: {
      margin: '1rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '1rem 1.5rem',
      textTransform: 'uppercase',
      color: 'tertiary',
      fontSize: ['1.5rem', '2rem'],
      textAlign: 'left',
      whiteSpace: 'nowrap',
      marginBottom: '1rem',
      overflow: 'hidden'
      // ':before': {
      //   content: "''",
      //   height: '2px',
      //   width: '40%',
      //   backgroundColor: 'lightgrey',
      //   marginRight: '3rem',
      // },
      // ':after': {
      //   content: "''",
      //   height: '2px',
      //   width: '40%',
      //   backgroundColor: 'lightgrey',
      //   marginLeft: '3rem',
      // },
    },
    menuSectionDescription: {
      textAlign: 'left',
      color: 'primary',
      fontSize: 4,
      marginBottom: '1.25rem',
      padding: '0rem 1.5rem',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      fontWeight: 'bold'
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem', '', '0 1rem'],
      marginBottom: '1rem',
      margin: ['', '0.2rem 0.5rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))']
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`
    },
    menuItemInnerContainer: {
      // marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch'
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['150px', '150px'],
      maxHeight: ['150px'],
      display: ['', '', '', 'none'],
      marginRight: '1rem'
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      maxWidth: ['150px', '150px'],
      maxHeight: ['150px'],
      cursor: 'pointer',
      borderRadius: '10px'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      width: '100%',
      position: 'relative'
    },
    menuItemName: {
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
      margin: '0px',
      paddingRight: '8px',
      fontFamily: 'Almarai, sans-serif',
      color: '#272424',
      fontWeight: 'bold',
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    menuItemDescription: {
      fontSize: '0.9rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      fontStyle: 'italic',
      opacity: '0.8',
      fontWeight: 'bold',
      color: 'primary'
    },

    menuItemCamera: {
      marginLeft: '0.5rem'
    },
    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end',
      fontWeight: 'bold'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      color: 'primary'
    },

    variantsContainer: {
      fontWeight: 'bold',
      color: 'primary'
    },

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex'
    },

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px'
    },
    menuItemPriceVariants: {
      color: 'black'
    },

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative'
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '0.75rem 1.75rem'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0 2.5%',
      display: 'flex',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center'
      }
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    },

    // ?================================
    // ?========== Empty Menu  =========
    // ?================================

    noItemsContainer: {
      width: '100%',
      minHeight: '30vh',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}
