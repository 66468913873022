import React, { useContext } from 'react'
import { Box, Image } from '@theme-ui/components'
import TextContent from '../Text/TextContent'
import { nanoid } from 'nanoid'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { store } from '../../context/store'
import Slide from 'react-reveal/Slide'
import storySelector from '../../helpers/storySelector'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const NextArrow = ({ currentSlide, slideCount, ...props }) => <FaChevronRight {...props} />

const PrevArrow = ({ currentSlide, slideCount, ...props }) => <FaChevronLeft {...props} />
const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}

export default function ContentBlockCenter({ storyId, video, sx, ...rest }) {
  const { globalState, dispatch } = useContext(store)
  const { stories } = globalState

  const { title, subtitle, body, images, buttons } = storySelector(storyId, stories)

  const noImagesToShow = images?.length === 1 && images[0] === 'https://res.cloudinary.com/gonation/' ? true : false

  if (globalState && images?.length) {
    return (
      <Box {...rest} sx={{ ...styles.container, ...sx }}>
        {!noImagesToShow ? (
          <Slider sx={styles.slider} {...settings} key={nanoid()}>
            {images.map((image, index) => (
              <Image src={image} sx={styles.image} />
            ))}
          </Slider>
        ) : (
          ''
        )}
        <Slide left>
          <TextContent
            sx={styles.textContent}
            className="content"
            title={title}
            subtitle={subtitle}
            text={body}
            buttons={buttons}
          />
        </Slide>
      </Box>
    )
  }
  return ''
}

const styles = {
  container: {
    position: 'relative',
    padding: '3rem 1rem'
  },
  image: {},
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'inherit',
    maxWidth: '767px',
    margin: '0 auto',
    h2: {
      color: 'inherit'
    },
    h3: {
      color: 'inherit'
    }
  },
  slider: {
    position: 'absolute',
    top: '0rem',
    left: '0rem',
    height: '100%',
    width: '100%',
    zIndex: '-1',
    '.slick-list, .slick-track, .slick-slide, .slick-slide > div, img ': {
      height: '100%'
    },
    '.slick-slide img': {
      objectFit: 'cover'
    }
  }
}
