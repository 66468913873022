import React, { useContext } from 'react'

import { Link } from 'gatsby'
import { Box, Heading, Image, Text, Link as ExternalLink } from 'theme-ui'

import { store } from '../../context/store'
import SocialIcons from '../UI/SocialIcons'
import Address from '../UI/Address'
import Phone from '../UI/Phone'
import InteractiveMap from '../UI/InteractiveMap'
import useBusinessData from '../../hooks/useBusinessData'

export default function TopFooter(props) {
  const { businessData } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Heading sx={styles.heading} as="h3">
          Get Social
        </Heading>
        {/* <Box sx={styles.contactDetails}> */}
        {/* <Phone sx={styles.phone} />
          <Box sx={styles.seperator} />
          <Address sx={styles.address} /> */}
        {/* </Box> */}
        <SocialIcons businessData={businessData} />
      </Box>
      {/* <Box sx={styles.map}>{businessData ? <InteractiveMap locations={businessData} /> : ''}</Box> */}
    </Box>
  )
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'stretch',
    position: 'relative',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ['100%', '', ''],
    padding: ['1rem', '', '2rem'],
    backgroundColor: 'primary'
  },
  heading: {
    color: 'light',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    fontSize: '2.5rem'
  },
  map: {
    width: ['100%', '', '50%'],
    backgroundColor: 'tertiary',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '.locationPopUp': {
      '.imageContainer': {
        display: 'none'
      }
    }
  },
  contactDetails: {
    display: 'flex',
    width: '100%'
  },
  phone: {
    color: 'light',
    fontSize: '1rem',
    padding: '1rem',
    width: '50%',
    svg: {
      color: 'light'
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6'
    }
  },
  seperator: {
    borderRight: 'solid 1px white'
  },
  address: {
    color: 'light',
    padding: '1rem',
    fontSize: '1rem',
    width: '50%',
    svg: {
      color: 'light'
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6'
    }
  }
}
