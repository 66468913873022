// example theme file

// const customStyles = require('./customStyles')
import menuV1 from './menuV1'

import customStyles from './customStyles'

const index = {
  colors: {
    text: '#666666',
    textOnPrimary: 'white',
    backgroundColor: 'light',
    primary: '#e4343a',
    primaryLight: '#ef4156',
    primaryDark: '#d51e23',
    textOnSecondary: 'white',
    secondary: '#fcbb39',
    secondaryLight: '#fec653',
    secondaryDark: '#f8b736',
    tertiary: '#008a4c',
    light: '#e5e5e5',
    dark: '#0d0d0d',
    grey: '#666666',
    darkGrey: '#606060',
    lightGrey: '#c7c7c7'
  },
  breakpoints: ['480px', '767px', '1024px', '1200px', '1500px', '1800px'],
  space: [
    '0rem',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem'
  ],
  fonts: {
    heading: 'Rubik, serif',
    subheading: 'Rubik, sans-serif',
    body: 'Roboto, serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700
  },
  lineHeights: {
    body: 1.85,
    heading: 1.125
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'capitalize',
      letterSpacing: '2px'
    },
    subheading: {
      fontFamily: 'subheading'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'grey',
      minHeight: '100vh',
      h1: {
        variant: 'text.heading',
        fontSize: 5
      },
      h2: {
        variant: 'text.heading',
        fontSize: 4
      },
      h3: {
        variant: 'text.heading',
        fontSize: 3
      },
      h4: {
        variant: 'text.heading',
        fontSize: 2
      },
      h5: {
        variant: 'text.heading',
        fontSize: 1
      },
      h6: {
        variant: 'text.heading',
        fontSize: 0
      },
      a: {
        textDecoration: 'none',
        color: 'white'
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit'
        }
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit'
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      }
    }
  },

  buttons: {
    primary: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: 'primary',
      color: 'light',
      border: 'solid 1px',
      borderRadius: '100px',
      width: 'fit-content',
      borderColor: 'primary',
      transition: 'all ease-in-out 0.5s',
      padding: '0.5rem 2rem',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      letterSpacing: '1px',
      fontWeight: 'bold',
      fontFamily: 'Almarai, sans-serif',
      svg: {
        marginRight: '0.5rem'
      },
      ':hover': {
        borderColor: 'primary',
        color: 'primary',
        backgroundColor: 'transparent'
      }
    },
    secondary: {},
    textOnly: {
      transition: 'all ease-in-out 0.3s',
      color: 'light',
      display: 'flex',
      ':hover': {
        opacity: '0.6'
      }
    }
  },

  ...menuV1,
  customStyles: {
    ...customStyles
  }
}

export default index
