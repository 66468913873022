import React from 'react'
import { Box } from 'theme-ui'
export default function BodyText({ body, children, ...rest }) {
  if (body) {
    return (
      <Box
        className="body"
        dangerouslySetInnerHTML={{ __html: body }}
        {...rest}
        sx={{ ...styles.container, ...rest.sx }}
      />
    )
  }
  if (children) {
    return (
      <Box className="body" {...rest} sx={{ ...styles.container, ...rest.sx }}>
        {children}
      </Box>
    )
  } else {
    return ''
  }
}

const styles = {
  container: {
    marginBottom: '1rem'
  }
}
