// ? === 3rd party imports ===
import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import { Box, Image, Heading } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import ArcText from 'arc-text'
import { jsx, css, keyframes } from '@emotion/react'

// ? === local imports ===
import { ContextProvider, store } from '../context/store'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import { allLinks, navLinks, footerLinks } from './navLinks'
import LoaderSpinner from './UI/LoaderSpinner'
import { useLocation } from '@reach/router'
import SEO from './seo'
import ContactForm from './ContactForm'
import TextContent from './Text/TextContent'
import PageHero from './Heros/PageHero'
import ContactComponent from './contactComponent'
import FranchiseComponent from './franchiseComponent'
// ? === component ===
export default function Layout(props) {
  const { globalState, dispatch } = useContext(store)

  const data = useStaticQuery(graphql`
    query siteQuery {
      businessData {
        ...businessDataFields
      }
      poweredImages {
        ...poweredImagesData
      }

      configData {
        ...allConfigDataFields
      }
      allGeneral {
        edges {
          node {
            ...generalArticleData
          }
        }
      }
    }
  `)

  // ?====================================================
  // ?============   Scrolled Functionility   ============
  // ?====================================================

  const [scrolled, setScrolled] = useState(true)

  const location = useLocation()

  const scrolledRef = useRef()
  scrolledRef.current = scrolled

  const handleScroll = useCallback(() => {
    const show = window.scrollY >= 0
    if (scrolledRef.current !== show) {
      setScrolled(show)
    }
  })

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    const element = document.getElementById('roundedText')
    if (element) {
      const arcText = new ArcText(element)
      arcText.arc(0)
      arcText.forceWidth(200)
      element.style.opacity = '1'
    }

    return () => {}
  }, [])

  // ?====================================================
  // ?====================================================
  // ?====================================================

  const { id, desc } = data?.businessData
  const { imageBaseUrl, imagePrefix } = data?.businessData?.avatar

  const logo = imageBaseUrl + '/' + imagePrefix

  useEffect(() => {
    if (globalState.loadingApp) {
      dispatch({
        type: 'SET_APP_DATA',
        configData: data.configData,
        businessData: data.businessData,
        stories: data.allGeneral.edges,
        poweredImages: data.poweredImages
      })
      setTimeout(() => {
        dispatch({
          type: 'FINISH_APP_LOADING',
          loadingApp: false
        })
      }, 400)
    }
    return () => {}
  }, [globalState.loadingApp])

  useEffect(() => {
    dispatch({ type: 'CLOSE_NAVIGATION' })

    return () => {}
  }, [location.pathname])

  const renderApp = () => {
    if (globalState.loadingApp) {
      return (
        <Box sx={styles.spinner}>
          <Image src={logo} sx={styles.logo} alt="Banana King..." />
          <br></br>
          <LoaderSpinner />

          <Box sx={styles.roundedTextContainer}>
            <Heading id="roundedText">King Of Fast Food</Heading>
          </Box>
        </Box>
      )
    } else {
      return (
        <>
          <SEO title={'Banana King'} favicon={logo} description={desc} />
          <Header location={props.location} navLinks={navLinks} allLinks={allLinks} />

          {props.children}
          <Footer links={footerLinks} />
        </>
      )
    }
  }

  // ? ============================================
  // ? ============================================
  // ? ============================================

  const isHomepage = location.pathname === '/'
  const isContactPage = location.pathname.includes('contact')
  const isFranchisePage = location.pathname.includes('franchises')

  const decideRender = () => {
    if (isContactPage) {
      return (
        <>
          {/* <SEO title={'Banana King'} favicon={logo} description={desc} /> */}

          {/* <Header location={location} navLinks={navLinks} allLinks={allLinks} /> */}
          <ContactComponent></ContactComponent>
          {/* <Footer links={footerLinks} /> */}
        </>
      )
    }
    if (isFranchisePage) {
      return <FranchiseComponent></FranchiseComponent>
    }
    // if (isFranchisePage) {
    //   return (
    //     <>
    //       {/* <SEO title={'Banana King'} favicon={logo} description={desc} /> */}
    //       {/* <Header
    //         location={props.location}
    //         navLinks={navLinks}
    //         allLinks={allLinks}
    //       /> */}
    //       <FranchiseComponent></FranchiseComponent>
    //       {/* <Footer links={footerLinks} /> */}
    //     </>
    //   );
    // }
    return renderApp()
  }

  return (
    <Box
      sx={styles.container}
      className={`${isHomepage ? 'Homepage' : ''} ${isHomepage && scrolled ? 'scrolledHomepage' : ''}  ${
        scrolled ? 'scrolled' : ''
      }`}
    >
      {decideRender()}
      {/* {renderApp()} */}
    </Box>
  )
}

// ? === styles ===

const rotate = keyframes`
  0% {
		transform: rotate(0);

  }

	100% {
    transform: rotate(-360deg);
  }
`

const styles = {
  container: {
    position: 'relative'
  },

  roundedTextContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '#roundedText': {
      opacity: '0',
      fontSize: '4rem',
      color: 'white',
      fontWeight: '400',
      transformOrigin: 'center',
      animation: `${rotate} 10s ease-in-out`,
      animationIterationCount: 'infinite',
      animationDirection: 'normal',
      animationTimingFunction: 'linear'
    }
  },

  spinner: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'secondary'
  },
  logo: {
    // filter: 'brightness(0)',

    maxHeight: '120px',
    paddingLeft: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: ['0rem', '1rem']
  },

  textContent: {
    padding: '0rem 1rem',
    width: ['100%', '', '50%'],
    flexGrow: '1',

    '.body': {
      maxWidth: '500px'
    }
  },

  hours: {
    width: ['100%', '', '50%'],
    flexGrow: '1',
    padding: '0rem 1rem',
    borderLeft: ['', '', 'solid 1px lightgrey'],
    '.innerHoursContainer': {
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      flexWrap: 'wrap'
      // 'div:last-child': {
      //   paddingRight: '0rem',
      // },
    },
    '.heading': {
      width: '100%',
      marginBottom: '1.5rem',
      color: 'tertiary',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      letterSpacing: '2px',
      marginBottom: '1rem',
      fontSize: ['1.75rem', '2rem']
      // fontFamily: 'Playfair Display, serif',
    },
    '.typeContainer': {
      padding: '1rem 1rem 0rem 0rem',
      order: '2'
    },
    '.typelabel': {
      color: 'primary',
      textTransform: 'uppercase',
      marginBottom: '0.5rem',
      fontSize: '1.2rem'
    },
    '.day': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '1rem'
    },
    '.time': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '0.9rem',
      color: 'grey'
    },

    '.brunch': {
      order: '1'
    }
  },
  textContentForm: {
    padding: ['3rem 1rem', '', '3rem 2rem'],
    marginTop: ['2rem', '', '0rem'],

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'secondary'
  },
  contactFormContainer: {
    backgroundColor: 'secondary'
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: ['0rem 1rem 4rem', '', '0rem 2rem 4rem'],
    maxWidth: '600px',
    margin: '0 auto',
    input: {
      borderColor: 'tertiary',
      borderRadius: '0px',
      color: 'black'
    },
    textarea: {
      borderColor: 'tertiary',
      borderRadius: '0px',
      color: 'black'
    },
    '.submitBtn': {
      marginTop: '2rem',
      padding: '1rem 3rem'
    }
  },
  phone: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: 'grey',
    marginBottom: '1rem',
    fontSize: '1rem',
    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  },
  address: {
    color: 'grey',
    marginBottom: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    fontSize: '1rem',

    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  }
}
