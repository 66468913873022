import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { Box, Image, Button } from 'theme-ui'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'
import { IoRestaurantOutline, IoLocationSharp } from 'react-icons/io5'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

// local imports
import { store } from '../../context/store'
import Fade from 'react-reveal/Fade'
import { globalHistory } from '@reach/router'
import Phone from '../UI/Phone'
import BottomNav from './BottomNav'

import LocationsPopUp from '../UI/LocationsPopUp'
import Logo from '../UI/Logo'
import DropdownNavItem from './dropdownComponents/DropdownNavItemV1'
import findPoweredImageURLById from '../../helpers/findPoweredImageURLById'

export default function Header(props) {
  const { globalState, dispatch } = useContext(store)
  const { businessData } = props

  const [navOpen, setNavOpen] = useState(globalState.appState.navigationMenu)
  const [scrolled, setScrolled] = useState(true)

  const navRef = useRef()
  navRef.current = scrolled

  const handleScroll = useCallback(() => {
    const show = window.scrollY >= 70
    if (navRef.current !== show) {
      setScrolled(show)
    }
  }, [])

  // sets state to scrolled if the user is not at the top of the page
  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // disable scroll when nav open
  useEffect(() => {
    globalState.appState.navigationMenu ? disablePageScroll() : enablePageScroll()
    return () => {}
  }, [globalState.appState.navigationMenu])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setNavOpen(false)
    })
  }, [globalState.appState.navigationMenu])

  const renderDesktopNavLinks = (link, navIndex, isNavBar) => {
    if (link?.dropdownLinks) {
      return (
        <DropdownNavItem
          key={`${link.name}${navIndex}`}
          navLinks={link}
          navIndex={`${isNavBar ? 'navBar' : 'navMenu'}${navIndex}`}
          isNavBar={isNavBar}
        />
      )
    } else {
      return (
        <Link sx={styles.navMenuItem} to={link.link} key={`${link.name}${navIndex}`}>
          {link.name}
        </Link>
      )
    }
  }

  const toggleOnlineOrderWidget = () => {
    setNavOpen(false)
    dispatch({
      type: 'TOGGLE_ONLINE_ORDERING_WIDGET'
    })
  }
  const toggleLocationsWidget = () => {
    setNavOpen(false)
    dispatch({
      type: 'TOGGLE_LOCATIONS_WIDGET'
    })
  }

  const navOpenVariant = globalState.appState.navigationMenu ? styles.navMenuOpen : {}
  const navBlockOpenVariant = globalState.appState.navigationMenu ? styles.navBlockOpen : {}

  const containerScrolledVariant = scrolled ? styles.containerScrolled : {}
  const logoScrolledVariant = scrolled ? styles.logoContainerScrolled : {}

  return (
    <>
      <Box sx={{ ...styles.container, ...containerScrolledVariant }} as="header" id="navigation">
        <Fade left>
          <Link to="/">
            <Box sx={{ ...styles.logoContainer, ...logoScrolledVariant }}>
              <Logo businessData={businessData} />
            </Box>
          </Link>
        </Fade>
        <Fade right>
          <Box
            sx={styles.hamburgerContainer}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_NAVIGATION'
              })
            }
            // setNavOpen(!navOpen)
          >
            <GiHamburgerMenu size={30} color={'#6c1918'} />
            Menu
          </Box>
        </Fade>

        <Box sx={styles.desktopNav}>
          <Fade bottom cascade>
            {props.navLinks.map((link, index) => renderDesktopNavLinks(link, index, true))}
          </Fade>
        </Box>
        {/* <Phone sx={styles.phone} /> */}
        <Box sx={styles.ctaButtons}>
          <LocationsPopUp sx={styles.locations} />
        </Box>
      </Box>

      <Box sx={{ ...styles.navMenu, ...navOpenVariant }}>
        <AiOutlineClose
          sx={styles.closeBtn}
          onClick={() =>
            dispatch({
              type: 'TOGGLE_NAVIGATION'
            })
          }
        />
        <Box sx={styles.links}>
          <Link to="/">
            <Logo sx={styles.navMenuLogo} businessData={businessData} />
          </Link>
          {props.navLinks.map((link, index) => (
            <Fade top delay={index + 1 * 500}>
              {renderDesktopNavLinks(link, index, false)}
            </Fade>
          ))}
          <Button sx={styles.cta} onClick={toggleOnlineOrderWidget}>
            <Link to="/locations">
              <IoRestaurantOutline /> Online Order
            </Link>
          </Button>

          {/* <Button sx={styles.cta} onClick={toggleOnlineOrderWidget}>
            <IoRestaurantOutline /> Online Order
          </Button> */}
          <Button
            sx={styles.cta}
            // onClick={toggleLocationsWidget}
            onClick={() => navigate('/locations')}
          >
            <IoLocationSharp />
            Locations
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ ...styles.navBlock, ...navBlockOpenVariant }}
        onClick={() =>
          dispatch({
            type: 'TOGGLE_NAVIGATION'
          })
        }
      ></Box>

      <BottomNav businessData={businessData} />
    </>
  )
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: ['space-between', '', 'flex-start'],
    backgroundColor: 'tertiary',
    alignItems: ['center', '', 'center'],
    position: 'fixed',
    width: '100%',
    top: '0rem',
    padding: ['0rem 0rem 0rem 1rem'],
    zIndex: '5000',
    transition: 'all ease-in-out 0.5s',
    borderBottom: 'none',
    height: '63px',
    zIndex: '5000'
  },
  containerScrolled: {
    padding: ['0rem 0.5rem', '0rem 0rem 0rem 1rem'],
    backgroundColor: 'tertiary'
  },
  logoContainer: {
    maxWidth: ['100px', '100px'],
    marginRight: '0.25rem',
    paddingRight: '0.5rem',
    borderRight: 'solid 1px lightgrey',
    transition: 'all ease-in-out 0.5s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      // filter: 'brightness(0)',
    }
  },
  logoContainerScrolled: {
    img: {
      // filter: 'brightness(0)',
    }
  },
  logo: {
    width: '100%'
  },
  hamburgerContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    cursor: 'pointer',
    svg: {
      marginRight: '1',
      transition: 'all ease-in-out 0.5s',
      borderRadius: '100px',
      padding: '0.25rem'
    },
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      opacity: '0.7'
    }
  },
  desktopNav: {
    padding: ['', '', '0rem 1rem', '0rem 1rem', '0rem 2rem'],
    display: ['none', '', '', 'flex'],
    margin: ['0 0 0 auto'],
    a: {
      variant: 'buttons.textOnly',
      padding: '0.5rem',
      color: 'white',
      textAlign: 'center',
      fontSize: ['0.9rem', '', '', '', '1rem'],
      margin: '0rem 0rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      ':hover': {
        textDecoration: 'underline'
      }
    }
  },

  phone: {
    display: ['none', '', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    svg: {
      color: 'primary',
      marginRight: '2',
      width: '1rem',
      height: '1rem'
    },
    fontWeight: 'bold',
    fontSize: '0.8rem',
    margin: '0rem 0.25rem 0rem auto',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  ctaButtons: {
    display: 'flex',
    position: ['fixed', 'static'],
    left: '0rem',
    bottom: '42px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: '0rem 0rem 0rem auto',
    width: ['100%', 'fit-content'],
    height: 'inherit',
    border: 'none'
  },
  onlineOrder: {
    // margin: ['0rem', '0rem 0rem 0rem 0.5rem'],
    margin: '0rem',

    fontSize: '0.7rem',
    borderRadius: ['0px', '', '0px', '0px'],
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    cursor: 'pointer',
    flexGrow: ['1', 'unset'],
    border: ['solid 1px white', 'unset'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    fontSize: ['0.7rem', '0.85rem'],
    padding: ['0.5rem', '', '1rem'],
    svg: {
      fontSize: '1.25rem'
    }
  },

  locations: {
    color: 'white',
    backgroundColor: '#1d6726',
    margin: '0rem',
    display: 'flex',
    borderRadius: ['0px', '', '0px', '0px'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: ['1', 'unset'],
    cursor: 'pointer',
    fontSize: ['0.7rem', '0.85rem'],
    padding: ['0.5rem', '', '1rem'],

    border: ['solid 1px white', 'unset'],
    svg: {
      fontSize: '1.25rem'
    }
  },
  navMenu: {
    position: 'fixed',
    left: '-150vw',
    backgroundColor: 'tertiary',
    height: '100vh',
    width: '100%',
    zIndex: '6000',
    transition: 'all ease-in-out 0.5s',
    padding: ['1rem 2rem', '1rem 3rem'],
    display: 'flex',
    justifyContent: ['flex-start', 'center'],
    alignItems: 'center',
    flexDirection: 'column'
  },
  navMenuOpen: {
    left: '0vw'
  },

  navMenuItem: {
    variant: 'customStyles.iconText',
    color: 'grey',
    fontSize: ['1.5rem', '', '2rem'],
    textAlign: 'center',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: ['0.5rem 0rem', '0.75rem 0rem', '0rem 0rem'],
    svg: {
      color: 'primary',
      marginRight: '0.5rem'
    },
    ':hover': {
      color: 'primary'
    }
  },
  closeBtn: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '40px',
    height: '40px',
    padding: '0.5rem',
    backgroundColor: 'primary',
    color: 'white',
    borderRadius: '500px'
    // opacity: '0.7',
  },
  navBlock: {
    position: 'fixed',
    left: '-100vw',
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(2px)',
    height: '100vh',
    width: '100vw',
    zIndex: '5500',
    transition: 'all ease-in-out 0.5s'
  },
  navBlockOpen: { left: '0vw' },

  navMenuLogo: {
    maxWidth: ['150px', '200px'],
    marginBottom: ['1rem', '2rem', '2rem']
  },
  links: {
    maxHeight: '87.5vh',
    overflowY: 'scroll',
    width: '100%',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    MsOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
    a: {
      color: 'white',
      lineHeight: ['1.4', '1.5', '1.75'],
      ':hover': {
        color: 'lightgrey'
      }
    }
  },
  cta: {
    margin: '0.5rem 0rem 0.5rem',
    padding: '0.5rem 1rem'
  }
}
